.Avatar-module__xOfkFW__avatar-wrapper {
  width: var(--width);
  min-width: var(--width);
  height: var(--height);
  min-height: var(--height);
  justify-content: center;
  align-items: center;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

